import * as Sdk from '@wix/client-search-sdk';
import { CategoryList } from '@wix/search-results-settings-definitions';
import { ISearchLocation } from './location';
import {
  FederatedSuggestions,
  SuggestionGroup,
  SuggestionItem,
} from '@wix/search-box';
import { ISearchDocument } from '@wix/client-search-sdk';
import { limitSuggestions } from './limitSuggestions';

export const convertResponseToSuggestions = ({
  federatedResponse,
  categoryList,
  searchQuery,
  footerUrl,
  searchLocation,
  absoluteUrl,
}: {
  federatedResponse: Sdk.FederatedSuggestions;
  categoryList: CategoryList;
  searchQuery: string;
  footerUrl: string;
  searchLocation: ISearchLocation;
  absoluteUrl: string;
}): FederatedSuggestions => {
  const { results: groups } = federatedResponse;

  let globalIndex = 0;

  const createGroupItem = ({
    documentType,
    title,
  }: {
    documentType: Sdk.SearchDocumentType;
    title: string;
  }): SuggestionGroup => {
    const relativeUrl = searchLocation.encodePath({
      documentType: documentType,
      query: searchQuery,
    });

    const url = `${absoluteUrl}/${relativeUrl}`;
    return {
      type: 'group',
      url,
      title,
      link: {
        namespace: 'SearchResults',
        key: 'searchSuggestions.groupLinkText',
        default: 'Show all',
      },
      data: {
        groupId: documentType,
        query: searchQuery,
        url,
        type: 'group',
      },
    };
  };

  const createDocumentItem = (document: ISearchDocument): SuggestionItem => {
    return {
      type: 'item',
      url: document.url,
      title: document.title,
      description: document.description,
      image: document.image,
      data: {
        globalIndex: globalIndex++,
        groupId: document.documentType,
        query: searchQuery,
        url: document.url,
        type: 'item',
      },
    };
  };

  const items: FederatedSuggestions['items'] = groups
    .filter(
      group =>
        group.documents.length && categoryList[group.documentType].visible,
    )
    .sort(
      (a, b) =>
        categoryList[a.documentType].index - categoryList[b.documentType].index,
    )
    .reduce(limitSuggestions, [])
    .reduce(
      (items, group) => [
        ...items,
        createGroupItem(group),
        ...group.documents.map(createDocumentItem),
      ],
      [],
    );

  return {
    items,
    footer: { url: footerUrl, text: `Search All "${searchQuery}"` },
  };
};
